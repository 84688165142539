import RestService from "../../../../services/rest.service";
import {IRootScopeService, IScope} from "angular";
import {
  AdjustLicenceType,
  OrganisationLicenceSetAndAvailableData,
  SingleLicenceSetAndAvailable,
  UpdateOrganisationLicenseRequest,
  User
} from "../../../../data/admin.data";
import AdminService from "../../../../services/admin.service";
import PrivilegeService from "../../../../services/privilege.service";
import {UserAccount} from "../../../../data/account.data";
import angular = require("angular");

require('./admin.users.licence.component.css')
export default class AdminUsersLicenceComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.users.licence.component.html');
    this.scope = {
      user: '=',
    };
    this.controller = UserLicenceController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class UserLicenceController {
  public restService: RestService;
  public adminService: AdminService
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public user: User;
  public licenceStats: OrganisationLicenceSetAndAvailableData;
  public isValid: Map<AdjustLicenceType, boolean>;
  public licenceType: typeof AdjustLicenceType = AdjustLicenceType;
  public minNumbers: Map<AdjustLicenceType, number>;
  public maxNumbers: Map<AdjustLicenceType, number>
  // no editing until licence stats are loaded
  public isLoading: boolean;
  // update licence process
  public isUpdating: boolean;
  // unsaved changes
  public isDirty: boolean;

  public account: UserAccount;

  constructor(restService: RestService, $scope: angular.IScope, $rootScope: angular.IRootScopeService, adminService: AdminService,
              public privilegeService: PrivilegeService, public dataService) {
    this.restService = restService;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.adminService = adminService;
    this.isValid = new Map<AdjustLicenceType, boolean>();
    this.minNumbers = new Map<AdjustLicenceType, number>();
    this.maxNumbers = new Map<AdjustLicenceType, number>();
    this.isLoading = true;
    this.isUpdating = false;
    this.isDirty = false;


    this.$scope.$watch('ctrl.user', (oldValue, newValue: User) => {
      if (angular.isDefined(newValue)) {
        this.user = newValue;
      } else if (angular.isDefined(oldValue)) {
        this.user = oldValue;
      }
      if (angular.isDefined(this.user)) {
        this.isLoading = true;
        this.restService.getOrganisationLicenceInfo(this.user.id).then((licenceStats) => {
          this.licenceStats = licenceStats;
          this.licenceStats.numbersForLicences.forEach(licence => this.validate(licence.licenceType));

        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
        })

      }
    });
  }

  getValidity(type: AdjustLicenceType) {
    if (this.isValid.has(type)) {
      return this.isValid.get(type);
    }
    return true;
  }

  validate(type: AdjustLicenceType) {
    var numbersForLicences = this.licenceStats.numbersForLicences.find(licenceType => licenceType.licenceType === type);

    this.setMinMaxNumbers(numbersForLicences);
    let nbr: number;

    switch (type) {
      case AdjustLicenceType.PERSONS:
        if (this.user.licenseType === 'CLIENT') {
          this.isValid.set(type, true);
          return;
        }
        nbr = this.user.licenceDetails.nbrOfPersons;
        break;
      case AdjustLicenceType.AMOBILE:
        nbr = this.user.licenceDetails.nbrOfAMLs;
        break;
      case AdjustLicenceType.AM4:
        nbr = this.user.licenceDetails.nbrOfAM3s;
        break;
      case AdjustLicenceType.OBJECTS:
        nbr = this.user.licenceDetails.nbrOfObjects;
        break;
      case AdjustLicenceType.DANGEROUS_GOODS:
        nbr = this.user.licenceDetails.nbrOfDangerousGoods;
        break;
      case AdjustLicenceType.DANGEROUS_SUBSTANCES:
        nbr = this.user.licenceDetails.nbrOfDangerousSubstances;
        break;
      case AdjustLicenceType.PATIENT_MANAGEMENT:
        nbr = this.user.licenceDetails.nbrOfPatientManagement;
        break;
      case AdjustLicenceType.AIR_RESCUE:
        nbr = this.user.licenceDetails.nbrOfAirRescue;
        break;
      case AdjustLicenceType.HERE_MAPS:
        nbr = this.user.licenceDetails.nbrOfHereMaps;
        break;
      case AdjustLicenceType.MAP_TRIP:
        nbr = this.user.licenceDetails.nbrOfMapTrip;
        break;
      case AdjustLicenceType.KOHLHAMMER:
        nbr = this.user.licenceDetails.nbrOfKohlhammer;
        break;
      case AdjustLicenceType.VEHICLE_CARDS:
        nbr = this.user.licenceDetails.nbrOfVehicleCards;
        break;
      case AdjustLicenceType.LOCATION_MAPS:
        nbr = this.user.licenceDetails.nbrOfLocationMaps;
        break;
      case AdjustLicenceType.EXPERT_WEATHER:
        nbr = this.user.licenceDetails.nbrOfExpertWeather;
        break;
    }


    if (nbr !== numbersForLicences.setNbrOrganisation) {
      this.isDirty = true;
    }
    if (nbr === 0 && numbersForLicences.setNbrOrganisation === 0 && numbersForLicences.availableInFE2Licence === 0) {
      //licence is not contained
      this.isValid.set(type, true);
      return;
    }
    if (nbr > (numbersForLicences.setNbrOrganisation + numbersForLicences.availableInFE2Licence)) {
      this.isValid.set(type, false);
      return;
    }
    if (nbr < numbersForLicences.usedNbrOrganisation) {
      this.isValid.set(type, false);
      return;
    }
    this.isValid.set(type, true);

  }

  anyValueInvalid() {
    return Array.from(this.isValid.values()).some(value => value === false, 1);
  }

  isLicenceSettingHelpDefined(type: AdjustLicenceType) {
    var min = this.minNumbers.get(type);
    var max = this.maxNumbers.get(type);
    if (angular.isUndefined(min)) {
      return false;
    }
    if (angular.isUndefined(max)) {
      return false;
    }
    return true;
  }

  updateLicence() {
    this.isUpdating = true;
    var licenceRequest = {
      newLicenceDetailsForUser: this.user.licenceDetails
    } as UpdateOrganisationLicenseRequest
    this.adminService.updateOrganisationLicence(licenceRequest, this.user.id).catch(() => {
    }).finally(() => {
      this.isUpdating = false;
      this.isDirty = false;
      this.$scope.$applyAsync();
    })
  }

  private setMinMaxNumbers(numbersForLicences: SingleLicenceSetAndAvailable) {
    this.minNumbers.set(numbersForLicences.licenceType, numbersForLicences.usedNbrOrganisation);
    if (this.user.licenseType === 'CLIENT' && numbersForLicences.licenceType === AdjustLicenceType.PERSONS) {
      this.maxNumbers.set(numbersForLicences.licenceType, 0);
    } else {
      this.maxNumbers.set(numbersForLicences.licenceType, (numbersForLicences.setNbrOrganisation + numbersForLicences.availableInFE2Licence));
    }
  }
}