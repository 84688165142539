import {IScope} from "angular";
import {AlarmMicroserviceMode, DeveloperSettings, KubernetesCluster} from "../../../../../data/admin.data";
import RestService from "../../../../../services/rest.service";

'use strict';

require('./admin.developer.component.scss');

export default class AdminDeveloperSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.developer.component.html');
    this.scope = {
    };
    this.controller = AdminDeveloperComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AdminDeveloperComponentController {
  private isLoading: boolean = false;
  private restService: RestService;
  private $scope: IScope;
  private settings: DeveloperSettings = {
    useEntrypoint: false,
    alarmMicroserviceMode: AlarmMicroserviceMode.NONE,
    kubernetesTarget: KubernetesCluster.DEV
  };


  constructor($scope: IScope, restService: RestService) {
    this.restService = restService;
    this.$scope = $scope;
    this.load();
  }


  load() {
    this.isLoading = true;
    this.restService.loadDeveloperSettings().then(settings => {
      this.settings = settings;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });

  }

  save() {
    this.isLoading = true;
    this.restService.saveDeveloperSettings(this.settings).then(() => {
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }
}
