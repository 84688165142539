import {Vehicle} from "./vehicles.data";

export interface AMobile {
  id: string;
  userId: string;
  password: string;
  passwordAsKeySpec: string;
  name: string;
  clientId?: string;
  mqttAlarmId: string;
  mqttStatusId?: string;
  vehicleId?: string;
  connectedDeviceId: string;
  connectedDeviceName: string;
  connectedDeviceType?: string;
  receiveStatus: boolean;
  displayPositions: boolean;
  submitStatus: ESubmitStatusValues;
  dangerousGoods: boolean;
  dangerousSubstances: boolean;
  patientManagement: boolean;
  airRescue: boolean;
  hereMaps: boolean;
  mapTrip: boolean;
  kohlhammer: boolean;
  vehicleCards: boolean;
  locationMaps: LocationMapsPermissions;
  dangerousGoodsStatus: string;
  dangerousSubstancesStatus: string;
  patientManagementStatus: string;
  airRescueStatus: string;
  hereMapsStatus: string;
  mapTripStatus: string;
  kohlhammerStatus: string;
  vehicleCardsStatus: string;
  locationMapsStatus: string;
  navigationStatus3: boolean;
  canEditAllMissionReports: boolean
  showMissionReportPopup: boolean
  gpsStatus: boolean;
  radiusStatus2: number;
  radiusStatus4: number;
  centerLat: string;
  centerLng: string;
  mapDisplayMode: EMapDisplayMode;
  vehicle: Vehicle;
  type: AMobileType;
  asTrackingSource: boolean;
  link?: string;
  allowSendStatus: boolean;
  allowSendGateControl: boolean;
  allowSendStrength: boolean;
}

export interface LocationMapsPermissions {
  permissions: ELicencePermissions;
  locationMapDiaryPermission: ELocationMapDiaryPermission;
  confidentialDiaryPermission: ELicencePermissions;
}

export enum ELocationMapDiaryPermission {
  WRITE = 'WRITE',
  READ = 'READ'
}

export enum ELicencePermissions {
  NO_ACCESS = 'NO_ACCESS',
  READ = 'READ',
  WRITE = 'WRITE'
}

export interface LicenceAvailabilityResult {
  available: boolean,
  notAvailableReason: ELicenceNotAvailableReason
}


export enum ELicenceNotAvailableReason {
  NOT_IN_LICENCE = 'NOT_IN_LICENCE',
  ALL_ASSIGNED = 'ALL_ASSIGNED',
  NOT_REVOKED = 'NOT_REVOKED'
}

export enum AMobileType {
  AMOBILE = 'AMOBILE',
  FOREST = 'FOREST'
}

export enum EAdditionalLicence {
  NAVLOG = 'NAVLOG',
  HERE_MAPS = 'HERE_MAPS',
  DANGEROUS_GOODS = 'DANGEROUS_GOODS',
  DANGEROUS_SUBSTANCES = 'DANGEROUS_SUBSTANCES',
  PATIENT_MANAGEMENT = 'PATIENT_MANAGEMENT',
  AIR_RESCUE = 'AIR_RESCUE',
  KOHLHAMMER = 'KOHLHAMMER',
  VEHICLE_CARDS = 'VEHICLE_CARDS',
  LOCATION_MAPS = 'LOCATION_MAPS',
  MAP_TRIP = 'MAP_TRIP',
  EXPERT_WEATHER = 'EXPERT_WEATHER'
}

export enum ESubmitStatusValues {
  NO_STATUS = 'NO_STATUS',
  GATE_CONTROL = 'GATE_CONTROL',
  FULL_ACCESS = 'FULL_ACCESS'
}

export enum EMapDisplayMode {
  ZOOM_ON_TARGET = 'ZOOM_ON_TARGET',
  ZOOM_ON_START_AND_TARGET = 'ZOOM_ON_START_AND_TARGET'
}


export interface AMobileAddressbookResponse {
  aMobiles: AMobile[],
  totalElements: number,
  licenced: number,
  inUse: number,
  licencedPercentage: number,
  remaining: number,
  totalPages: number
}