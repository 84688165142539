'use strict';

require('./color.picker.scss');

export default class FancyColorPickerComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'EA'
    this.scope = {
      translation: '=',
      data: '=',
      property: '=',
      deactive: '=',
      help: '='
    }
    this.template = require('./color.picker.html');

    this.controller = FancyColorPickerComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class FancyColorPickerComponentController {
  public translation: string;
  public property: string;
  public data: any;
  public deactive: boolean;
  public help: string;

}
