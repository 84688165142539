import angular from 'angular';
import 'angular-ui-bootstrap'

import services from '../../../services'

import ImageAssetRowComponent from './image.asset.row.component/image.asset.row.component';
import PoiRowComponent from './poi.tablerow.component/poi.tablerow.component';
import RoadblockRowComponent from './roadblock.tablerow.component/roadblock.tablerow.component';
import ObjectRowComponent from './objects.tablerow.component/objects.tablerow.component';
import MapPinComponent from './map.pin.component/map.pin.component';
import ObjectAccessComponent from './object.access.component/object.access.component';
import ObjectAddressComponent from './object.address.component/object.address.component';
import ObjectAlarmReasonsComponent from './object.alarm.reasons.component/object.alarm.reasons.component';
import ObjectBmaComponent from './object.bma.component/object.bma.component';
import ObjectBuildingsComponent from './object.buildings.component/object.buildings.component';
import ObjectContactsComponent from './object.contacts.component/object.contacts.component';
import ObjectDangerBarriersComponent from './object.danger.barriers.component/object.danger.barriers.component';
import ObjectDangerObjectsComponent from './object.danger.objects.component/object.danger.objects.component';
import ObjectDangerPersonsComponent from './object.danger.persons.component/object.danger.persons.component';
import ObjectDangerSubstancesComponent from './object.danger.substances.component/object.danger.substances.component';
import ObjectDangerSubstanceComponent from './object.danger.substance.component/object.danger.substance.component';
import ObjectDocumentsComponent from './object.documents.component/object.documents.component';
import ObjectElevatorsComponent from './object.elevators.component/object.elevators.component';
import ObjectEventComponent from './object.event.component/object.event.component';
import ObjectEventsComponent from './object.events.component/object.events.component';
import ObjectExinguishComponent from './object.extinguish.component/object.extinguish.component';
import ObjectFeedbackComponent from './object.feedback.component/object.feedback.component';
import ObjectFeedbackEntryComponent from './object.feedback.entry.component/object.feedback.entry.component';
import ObjectMapComponent from './object.map.component/object.map.component';
import ObjectNotesComponent from './object.notes.component/object.notes.component';
import ObjectOverallComponent from './object.overall.component/object.overall.component';
import ObjectPathsComponent from './object.paths.component/object.paths.component';
import ObjectProtocolComponent from './object.protocol.component/object.protocol.component';
import ObjectRadioComponent from './object.radio.component/object.radio.component';
import ObjectRisksComponent from './object.risks.component/object.risks.component';
import ObjectSubObjectsComponent from './object.subobjects.component/object.subobjects.component';
import ObjectVentilationComponent from './object.ventilation.component/object.ventilation.component';
import ObjectDirectioncardComponent from './object.directioncard.component/object.directioncard.component';
import ObjectLinksComponent from './object.links.component/object.links.component';
import ObjectPropertiesComponent from './object.properties.component/object.properties.component';
import ObjectExaminationsComponent from './object.examinations.component/object.examinations.component';
import HomeEmergencyComponent from './home.emergency.component/home.emergency.component';
import LockingSystemComponent from './locking.system.component/locking.system.component';
import PatientCapacityComponent from './patient.capacity.component/patient.capacity.component';
import HospitalServicesComponent from './hospital.services.component/hospital.services.component';
import EmergencyPowerComponent from './emergency.power.component/emergency.power.component';
import PolygonMapComponentController from './polygon.map.component/polygon.map.component';

export default angular.module('FE2.components.objects', ['ui.bootstrap', services])
  .directive('imageAssetRow', () => new ImageAssetRowComponent())
  .directive('poiRow', () => new PoiRowComponent())
  .directive('roadblockRow', () => new RoadblockRowComponent())
  .directive('objectsRow', () => new ObjectRowComponent())
  .directive('mapPin', () => new MapPinComponent())
  .directive('objectAccess', () => new ObjectAccessComponent())
  .directive('objectAddress', () => new ObjectAddressComponent())
  .directive('objectAlarmReasons', () => new ObjectAlarmReasonsComponent())
  .directive('objectBma', () => new ObjectBmaComponent())
  .directive('objectBuildings', () => new ObjectBuildingsComponent())
  .directive('objectContacts', () => new ObjectContactsComponent())
  .directive('objectDangerBarriers', () => new ObjectDangerBarriersComponent())
  .directive('objectDangerObjects', () => new ObjectDangerObjectsComponent())
  .directive('objectDangerPersons', () => new ObjectDangerPersonsComponent())
  .directive('objectDangerSubstances', () => new ObjectDangerSubstancesComponent())
  .directive('objectDangerSubstance', () => new ObjectDangerSubstanceComponent())
  .directive('objectDocuments', () => new ObjectDocumentsComponent())
  .directive('objectElevators', () => new ObjectElevatorsComponent())
  .directive('objectEvent', () => new ObjectEventComponent())
  .directive('objectEvents', () => new ObjectEventsComponent())
  .directive('objectExtinguish', () => new ObjectExinguishComponent())
  .directive('objectFeedback', () => new ObjectFeedbackComponent())
  .directive('objectFeedbackEntry', () => new ObjectFeedbackEntryComponent())
  .directive('objectMap', () => new ObjectMapComponent())
  .directive('objectNotes', () => new ObjectNotesComponent())
  .directive('objectOverall', () => new ObjectOverallComponent())
  .directive('objectPaths', () => new ObjectPathsComponent())
  .directive('objectProtocol', () => new ObjectProtocolComponent())
  .directive('objectRadio', () => new ObjectRadioComponent())
  .directive('objectRisks', () => new ObjectRisksComponent())
  .directive('objectSubobjects', () => new ObjectSubObjectsComponent())
  .directive('objectVentilation', () => new ObjectVentilationComponent())
  .directive('objectDirectioncard', () => new ObjectDirectioncardComponent())
  .directive('objectLinks', () => new ObjectLinksComponent())
  .directive('objectProperties', () => new ObjectPropertiesComponent())
  .directive('objectExaminations', () => new ObjectExaminationsComponent())
  .directive('homeEmergency', () => new HomeEmergencyComponent())
  .directive('lockingSystem', () => new LockingSystemComponent())
  .directive('emergencyPower', () => new EmergencyPowerComponent())
  .directive('patientCapacity', () => new PatientCapacityComponent())
  .directive('hospitalServices', () => new HospitalServicesComponent())
  .directive('polygonMap', () => new PolygonMapComponentController())
  .name;
