'use strict';

/* @ngInject */
import {IRootScopeService, IScope} from "angular";
import RestService from "../../../../services/rest.service";
import {EAdditionalLicence, ELicencePermissions, LicenceAvailabilityResult} from "../../../../data/amobile.data";
import PrivilegeService from "../../../../services/privilege.service";
import {AlarmMonitor, AMwebLicencedFeatures} from "../../../../data/am4.data";
import {RolePrivilege} from "../../../../data/privileges.enum";
import angular = require("angular");
import {an, el} from "@fullcalendar/core/internal-common";

require('./amweb.licences.component.scss')
export default class AmwebLicencesComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.scope = {
      amweb: '='
    };
    this.template = require('./amweb.licences.component.html');

    this.controller = AmwebLicencesComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

class AmwebLicencesComponentController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: any;

  public restService: RestService;
  public isLoading: boolean = false;
  public isLoadingCheck: boolean = false;
  public amweb: AlarmMonitor;
  public licencePermissions: typeof ELicencePermissions = ELicencePermissions;
  public licenceTypes: typeof EAdditionalLicence = EAdditionalLicence;
  public priv: PrivilegeService;
  public isEditingOfFieldsAllowed: boolean;
  // general list of licences available for purchase for AMweb
  public amWebAvailableLicences: EAdditionalLicence[] = [EAdditionalLicence.AIR_RESCUE, EAdditionalLicence.EXPERT_WEATHER];
  // map containing the licence data calculated if licence can be assigned
  public availableMap: Map<EAdditionalLicence, LicenceAvailabilityResult> = new Map<EAdditionalLicence, LicenceAvailabilityResult>();
  // list of this specific amwebs licences
  public amWebLicences: AMwebLicencedFeatures[] = [];
  public listeners = [];

  public airRescueHint: boolean = false;
  public expertWeatherHint: boolean = false;

  constructor(
    $scope: IScope, restService: RestService, privilegeService: PrivilegeService, $rootScope: IRootScopeService, $log: any) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.restService = restService;
    this.priv = privilegeService;
    this.$log = $log;
    this.isEditingOfFieldsAllowed = this.priv.has(RolePrivilege.Addressbook_AM4_Edit);

    this.listeners.push(this.$scope.$watch('ctrl.amweb', (oldValue: AlarmMonitor, newValue) => {
        if (newValue) {
          if (this.amweb.id) {
            this.amweb = newValue;
            this.isLoading = true;
            this.restService.loadAMwebLicences(this.amweb.id).then(response => {
              this.amWebLicences = response;
              this.isLoading = false;
              this.$scope.$applyAsync();
            })

            this.listeners.push(this.$rootScope.$on(`amweb.${this.amweb.id}.updated`, () => {
              if (this.amWebLicences && this.amWebLicences.length !== 0) {
                if (this.isEditingOfFieldsAllowed) {
                  this.restService.updateAMwebLicences(this.amweb.id, this.amWebLicences).then(response => {
                    this.amWebLicences = response;
                    this.$scope.$applyAsync();
                  }).catch(error => {
                      this.$log.error('update amweb licence failed');
                    }
                  )
                }
              }
            }));
            if (this.isEditingOfFieldsAllowed) {
              this.isLoadingCheck = true;
              this.checkIfLicenceIsAvailable().then(() => {
                this.isLoadingCheck = false;
                this.$scope.$applyAsync();
              });
            }
          }
        }
      })
    );


    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }


  hasLicenceAvailable(licence: EAdditionalLicence) {
    if (this.availableMap && this.availableMap.get(licence)) {
      return this.availableMap.get(licence).available;
    }
  }

  checkIfLicenceIsAvailable(): Promise<void> {
    return new Promise<void>(resolve => {
      this.amWebAvailableLicences.forEach(licence => {
        this.restService.checkIfLicenceAreAvailableAMWeb(licence).then(response => {
          this.availableMap.set(licence, response);
        }).finally(() => {
          if (this.availableMap.size === this.amWebAvailableLicences.length) {
            resolve()
          }
        });
      })
    });
  }

  switchLicence(licenceType: EAdditionalLicence) {
    var hintToggle = false;
    const amwebLicencedFeatures = this.amWebLicences.find(feature => feature.type === licenceType);
    if (amwebLicencedFeatures) {
      const licenceAvailabilityResult = this.availableMap.get(licenceType);
      if (licenceAvailabilityResult) {
        if (!licenceAvailabilityResult.available && amwebLicencedFeatures.active) {
          // allow disabling of toggle if no licences are available anymore
          amwebLicencedFeatures.active = false;
        } else if (licenceAvailabilityResult.available) {
          // free editing allowed if licences ara available
          amwebLicencedFeatures.active = !amwebLicencedFeatures.active;
          hintToggle = true;
        }
      }
    }
    this.amWebLicences.splice(this.amWebLicences.findIndex(feature => feature.type === licenceType), 1);
    this.amWebLicences.push(amwebLicencedFeatures);
    this.$scope.$applyAsync();
    return hintToggle

  }

  hasActiveLicence(licenceType: EAdditionalLicence): boolean {
    let amwebLicencedFeatures = this.amWebLicences.find(feature => feature.type === licenceType);
    if (!amwebLicencedFeatures) {
      amwebLicencedFeatures = {
        active: false,
        type: licenceType
      } as AMwebLicencedFeatures;
      this.amWebLicences.push(amwebLicencedFeatures);
      return false;
    }
    return amwebLicencedFeatures.active;
  }
}