'use strict';

/* @ngInject */
import {OrganizationPolygonRequest} from "../../../../data/account.data";
import {IRootScopeService, IScope} from "angular";
import RestService from "../../../../services/rest.service";
import {Polygon} from "geojson";

export default class EditOrganizationPolygonController {
  private $uibModalInstance;
  public organizationPolygon: OrganizationPolygonRequest;
  public title: string;
  public name: string;
  public color: string;
  public polygon: Polygon;
  public polygons: Polygon[];
  public isReadOnly: boolean;
  public center: number[];
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public restService: RestService;
  private defaultColor = "#001fff";

  private okFunction: Function;

  constructor($scope: IScope, $rootScope: IRootScopeService, restService: RestService, $uibModalInstance, organizationPolygon: OrganizationPolygonRequest,
              center:any, title:string, okFunction: Function) {
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
    this.title = title;
    this.organizationPolygon = organizationPolygon;
    if (!organizationPolygon) {
      this.organizationPolygon = new OrganizationPolygonRequest();
    }
      this.name = this.organizationPolygon.name;
      this.color = this.organizationPolygon.color;
      this.polygon = this.organizationPolygon.polygon;
      this.polygons = [this.polygon];
    if(!this.color){
      this.color=this.defaultColor;
    }
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.center = center;
    this.restService = restService;
  }

  cancel() {
    this.$uibModalInstance.close();
  }
  save() {
    this.saveOrganizationPolygon();
    this.$uibModalInstance.close('cancel');
  }


  updateMap(){
    if(!this.color){
      this.color = this.defaultColor;
    }
    this.$rootScope.$emit("polygon.update");
    this.$scope.$applyAsync();
  }

  private polygonDrown(){
    return this.polygons && this.polygons.length > 0 && this.polygons[0];
  }

  private saveOrganizationPolygon(){
    if(!this.organizationPolygon) {
      return;
    }
      this.organizationPolygon.name = this.name;
      this.organizationPolygon.color = this.color;
      this.organizationPolygon.polygon = this.polygons[0];

    this.restService.saveOrganizationPolygon(this.organizationPolygon)
      .then((result: OrganizationPolygonRequest) => {
        this.organizationPolygon = result;
      }).catch(() =>{
      this.organizationPolygon = null;
    }).finally(() => {
      this.okFunction();
    });
  }
}