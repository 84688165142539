'use strict';
import {ILogService, IRootScopeService} from "angular";
import {StatusGrid, EVehicleStatus, Vehicle} from "../data/vehicles.data";
import RestService from "./rest.service";
import {QueueEntryForBroadcast, QueueEntrySelectedEvent} from '../data/queue.data';
import {b} from "@fullcalendar/core/internal-common";

export default class VehicleService {
  private $log: ILogService;
  private restService: RestService;
  private statusColorMapping: Map<EVehicleStatus, string>;
  private statusTextColorMapping: Map<EVehicleStatus, string>;
  private bcQueueSelected: BroadcastChannel = new BroadcastChannel('queue.QUEUE_ENTRY_SELECTED');
  private statusTranslationMapping: Map<EVehicleStatus, string>;
  private statusIsPulsatingMapping: Map<EVehicleStatus, boolean>;

  constructor($log: ILogService, restService: RestService, private $rootScope: IRootScopeService) {
    this.$log = $log;
    this.restService = restService;
  }

  /**
   * Get all groups for the status tableau
   */
  getGroupsForTableau(forceUpdate: boolean): Promise<Array<string>> {
    return this.restService.getGroupsForTableau(forceUpdate);
  }


  /**
   * Schedules a pending reset timer task immediately (e.g. to reset STATUS_5 immedidately)
   * @param vehicle
   */
  schedulePendingResetTimerTask(vehicle: Vehicle): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.restService.schedulePendingResetTimerTask(vehicle).then(response => {
        if (response) {
          // after reset FE2 tells us the vehicle is assigned to one queue entry
          // so we fire an event that this entry was selected
          const eventData = {
            bcEntry: {
              entry: response,
              hasNewAdditionalRequest: response.hasNewAdditionalRequest,
              hasNewImportantMessage: response.hasNewImportantMessage
            } as QueueEntrySelectedEvent,
            vehicleId: vehicle.id
          } as QueueEntryForBroadcast;
          this.bcQueueSelected.postMessage(eventData);
        } else {
          // we ignore undefined or null, either vehice is not assigned or is assigned to more than one
        }
        resolve(true);
      }).catch(error => reject(error));
    });
  }

  /**
   * Load the grid for a given group
   * @param group
   */
  getGridForGroup(group: string): Promise<StatusGrid> {
    return this.restService.getGridForGroup(group);
  };

  getStatusColorMapping(): Promise<Map<EVehicleStatus, string>> {
    return new Promise<Map<EVehicleStatus, string>>((resolve) => {
      if (this.statusColorMapping === undefined) {
        this.restService.getStatusColorMapping().then(data => {
          this.statusColorMapping = data;
          resolve(this.statusColorMapping);
        })
      } else {
        resolve(this.statusColorMapping);
      }
    });
  }

  getStatusTranslationMapping(): Promise<Map<EVehicleStatus, string>> {
    return new Promise<Map<EVehicleStatus, string>>((resolve) => {
      if (this.statusTranslationMapping === undefined) {
        this.restService.getStatusTranslationMapping().then(data => {
          this.statusTranslationMapping = data;
          resolve(this.statusTranslationMapping);
        })
      } else {
        resolve(this.statusTranslationMapping);
      }
    });
  }

  getStatusIsPulsatingMapping(): Promise<Map<EVehicleStatus, boolean>> {
    return new Promise<Map<EVehicleStatus, boolean>>((resolve) => {
      if (this.statusIsPulsatingMapping === undefined) {
        this.restService.getStatusPulsatingMapping().then(data => {
          this.statusIsPulsatingMapping = data;
          resolve(this.statusIsPulsatingMapping);
        })
      } else {
        resolve(this.statusIsPulsatingMapping);
      }
    });
  }

  getStatusTextColorMapping(): Promise<Map<EVehicleStatus, string>> {
    return new Promise<Map<EVehicleStatus, string>>((resolve) => {
      if (this.statusTextColorMapping === undefined) {
        this.restService.getStatusTextColorMapping().then(data => {
          this.statusTextColorMapping = data;
          resolve(this.statusTextColorMapping);
        })
      } else {
        resolve(this.statusTextColorMapping);
      }
    });
  }
}