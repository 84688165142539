import {CalendarAccess, CalendarSimple, ECalendarType} from './../data/calendar.data';

'use strict';

import {ILogService, IRootScopeService} from "angular";
import {EEventState, EEventType, EventAccess, EventFeedbackResponse} from '../data/event.data';
import {DateClickArg} from '@fullcalendar/interaction';
import {WidgetData} from '../data/widget.data';
import RestService from './rest.service';
import {er} from "@fullcalendar/core/internal-common";

export default class CalendarService {

  private $rootScope: IRootScopeService;
  private $log: ILogService;
  private restService: RestService;
  public serviceNotAvailable: boolean = false;


  constructor($log: ILogService, $rootScope: IRootScopeService, restService: RestService) {
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.restService = restService;
  }

  /**
   * Get a calendar template to create a new calendar
   * @param type
   */
  getCalendarTemplate(type: ECalendarType): Promise<CalendarAccess> {
    return this.restService.getCalendarTemplate(type);
  }

  /**
   * Create a new calendar
   * @param calendarAccess
   */
  createCalendar(calendarAccess: CalendarAccess): Promise<CalendarAccess> {
    return this.restService.createCalendar(calendarAccess);
  }

  /**
   * Save an existing calendar
   * @param calendarAccess Calendar to save
   */
  saveCalendar(calendarAccess: CalendarAccess): Promise<CalendarAccess> {
    return this.restService.saveCalendar(calendarAccess);
  }

  /**
   * Get existing calendar by its ID
   * @param calendarId
   */
  getCalendarById(calendarId: string): Promise<CalendarAccess> {
    return new Promise<CalendarAccess>((resolve, reject) => {
      this.restService.getCalendarById(calendarId).then(result => {
        this.setAvailable();
        resolve(result);
      }).catch(() => {
        reject();
      })
    });
  }

  /**
   * Delete a calendar by its ID
   * @param calendarId
   */
  deleteCalendarById(calendarId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.restService.deleteCalendarById(calendarId).then((result) => {
        this.$rootScope.$broadcast('calendar.deleted', calendarId);
        resolve(result);
      }).catch(err => reject(err));

    });
  }

  /**
   * Get all calendars
   */
  getCalendars(): Promise<CalendarSimple[]> {
    return new Promise<CalendarSimple[]>((resolve, reject) => {
      this.restService.getCalendars().then((calendars: CalendarSimple[]) => {
        this.$rootScope.$broadcast('calendars.loaded', calendars);
        this.setAvailable();
        resolve(calendars);
      }).catch(err => {
        this.checkServiceAvailability(err);
        reject(err);
      });
    });
  }

  private checkServiceAvailability(error) {
    if (error.status === 503) {
      this.serviceNotAvailable = true;
    }
  }


  /**
   * Get a template for creating a new event
   * @param calendarId
   * @param eventType
   * @param info
   */
  getEventTemplateForCalendar(calendarId: string, eventType: EEventType, info: DateClickArg): Promise<EventAccess> {
    return this.restService.getEventTemplateForCalendar(calendarId, eventType, info ? info.dateStr : undefined);
  }

  /**
   * Get all events for a given calendar
   * @param calendarId
   */
  getEventsForCalendarId(calendarId: string): Promise<EventAccess[]> {
    return this.restService.getEventsForCalendarId(calendarId);
  }

  /**
   * Get feedback for given event
   * @param event
   */
  getFeedbacksForEvent(calendarId: string, eventId: string): Promise<EventFeedbackResponse> {
    return this.restService.getFeedbacksForEvent(calendarId, eventId);
  }

  /**
   * Get all events for given logged in user
   */
  getEventsForUserInMonth(month: number, year: number, withSurroundingMonths: boolean): Promise<EventAccess[]> {
    return new Promise<EventAccess[]>((resolve, reject) => {
      this.restService.getEventsForUserInMonth(month, year, withSurroundingMonths).then(response => {
        resolve(response)
        this.setAvailable();
      }).catch((err) => {
        this.checkServiceAvailability(err);
        reject(err);
      });
    });
  }

  /**
   * Get all events for given logged in user
   */
  getNumberOfUpcomingEvents(): Promise<WidgetData> {
    return this.restService.getNumberOfUpcomingEvents();
  }

  /**
   * Create a new event
   * @param eventAccess The event to create
   * @param notify Whether or not a notification should be sent upon creating the event
   */
  createEvent(eventAccess: EventAccess, notify: boolean): Promise<EventAccess> {
    return this.restService.createEvent(eventAccess, notify);
  }

  /**
   * Update an existing event
   * @param eventAccess The event to update
   * @param notify Whether or not a notification should be sent upon saving the event
   */
  saveEvent(eventAccess: EventAccess, notify: boolean, editUpcoming: boolean): Promise<EventAccess> {
    return this.restService.updateEvent(eventAccess, notify, editUpcoming);
  }


  /**
   * Delete an existing event
   * @param eventAccess The event to update
   */
  deleteEvent(eventAccess: EventAccess): Promise<boolean> {
    return this.restService.deleteEvent(eventAccess.event, false);
  }

  /**
   * Delete an existing event series
   * @param eventAccess The event to update
   */
  deleteEventSeries(eventAccess: EventAccess): Promise<boolean> {
    return this.restService.deleteEvent(eventAccess.event, true);
  }


  /**
   * Delete existing events by its state
   */
  deleteEventByState(calendarId: string, eventState: EEventState): Promise<boolean> {
    return this.restService.deleteEventsByState(calendarId, eventState);
  }

  dateToIsoString(date: Date) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };
    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);

  }

  private setAvailable() {
    this.serviceNotAvailable = false;
  }
}