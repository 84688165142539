import {ILogService, IRootScopeService, IScope} from "angular";
import PrivilegeService from "../../../../../services/privilege.service";
import RestService from "../../../../../services/rest.service";
import {ELogicalSectionTypes, SectionsResponse, SectionsTemplate} from "../../../../../data/sections.templates.data";
import angular = require("angular");

require('./sections.template.editor.scss');

export default class SectionsTemplateEditorComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public require: any;
  public bindToController: boolean;


  constructor() {
    this.restrict = 'E';
    this.template = require('./sections.template.editor.html');
    this.scope = {
      sectionsTemplate: '=',
      index: '='
    };
    this.controller = SectionsTemplateEditorController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class SectionsTemplateEditorController {
  public restService: RestService;
  public $log: ILogService;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public isLoading: boolean;

  public sectionsTemplate: SectionsTemplate;
  public entries: SectionsResponse[];
  public name: string;
  private countSectionTypes: ELogicalSectionTypes[] = [ELogicalSectionTypes.DEFAULT];


  constructor($log: ILogService, $scope: IScope, $rootScope: IRootScopeService, private $uibModal, restService: RestService,
              public privilegeService: PrivilegeService) {
    this.restService = restService;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$scope = $scope;

    this.$scope.$watch('ctrl.sectionsTemplate', (newValue) => {
      if (angular.isDefined(newValue)) {
        this.name = (newValue as SectionsTemplate).name;
        this.loadSectionsByTemplate((newValue as SectionsTemplate).id);
      }
    });

  }

  loadSectionsByTemplate(id: string) {
    this.restService.loadSectionsByTemplate(id).then((response: SectionsResponse[]) => {
      this.entries = response;
    }).finally(() => {
      this.isLoading = false;
      if (!this.entries) {
        this.entries = [];
      }
      this.$scope.$apply();
    });
  }

  editSection(section: SectionsResponse) {
    const sectionId = section.id;
    this.$uibModal.open({
      template: require('../../../../modals/addressbook/sections.editor/sections.editor.html'),
      controller: 'SectionsEditorController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        templateId: () => {
          return this.sectionsTemplate.id;
        },
        nbr: () => {
          return section.nbr
        },
        section: () => {
          return section;
        },
        okFunction: () => {
          return (updatedSection) => {
            this.updateSection(sectionId, updatedSection);
          }
        }
      }
    });
  }

  /**
   * Open modal and add a new Section
   */
  addSection() {
    this.$uibModal.open({
      template: require('../../../../modals/addressbook/sections.editor/sections.editor.html'),
      controller: 'SectionsEditorController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        templateId: () => {
          return this.sectionsTemplate.id;
        },
        nbr: () => {
          return this.getNextCount();
        },
        section: () => {
          return null;
        },
        okFunction: () => {
          return (section) => {
            this.newSection(section);
          }
        }
      }
    });
  }

  newSection(section: SectionsResponse) {
    this.restService.addSection(this.sectionsTemplate.id, section).then((response: SectionsResponse) => {
      this.entries.push(response);
    }).finally(() => {
      this.entries.sort((a, b) => a.nbr.localeCompare(b.nbr));
      this.isLoading = false;
      this.$scope.$apply();
    });
  }

  updateSection(sectionId: string, section: SectionsResponse) {
    this.restService.editSection(this.sectionsTemplate.id, sectionId, section).then((response: SectionsResponse) => {
      let edited = false;
      for (let i = 0; !edited && i < this.entries.length; i++) {
        if (this.entries[i].id == sectionId) {
          this.entries[i] = response;
          edited = true;
        }
      }
      if (!edited) {
        this.entries.push(response);
      }
    }).finally(() => {
      this.entries.sort((a, b) => a.nbr.localeCompare(b.nbr));
      this.isLoading = false;
      this.$scope.$apply();
    });
  }

  deleteSection($event, section: SectionsResponse) {
    $event.stopPropagation();
    $event.preventDefault();
    this.restService.removeSection(this.sectionsTemplate.id, section.id).then(() => {
      this.entries = this.entries.filter(s => s.id != section.id);
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$apply();
    });
  }

  save() {
    if (!this.name) {
      return;
    }
    this.sectionsTemplate.name = this.name;
    if (!this.sectionsTemplate.description) {
      this.sectionsTemplate.description = '';
    }
    this.restService.editTemplate(this.sectionsTemplate.id, this.sectionsTemplate.name, this.sectionsTemplate.description).then((response: SectionsTemplate) => {
      this.sectionsTemplate = response;
    }).finally(() => {
      this.isLoading = false;
      if (!this.entries) {
        this.entries = [];
      }
      this.$scope.$apply();
    });
  }

  delete() {
    this.isLoading = true;
    this.restService.deleteTemplate(this.sectionsTemplate.id).then(() => {
      // Update
      this.$rootScope.$emit('update.sectionsTemplate.list');
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$apply();
    });
  }


  private getNextCount(): number {
    const listOffCountableSections = this.entries.filter(section => this.countSectionTypes.some(type => section.logicalType === type));
    return listOffCountableSections.length + 1;
  }
}