import {IScope} from "angular";
require('./mission.report.publish.confirm.modal.scss')

export default class MissionReportPublishConfirmModalController {
  public okFunction: any;
  public $scope: IScope;
  public $uibModalInstance: any;
  public createAndSendMissionConfirmation: boolean = false;
  public hasMailServiceEnabled: boolean;

  constructor(okFunction: any, $scope: IScope, $uibModalInstance: any, public dataService: any) {
    this.okFunction = okFunction;
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.hasMailServiceEnabled = this.dataService.getAccount().hasMailServiceEnabled;
  }

  confirm(){
    this.okFunction(true, this.createAndSendMissionConfirmation);
    this.$uibModalInstance.close();
  }
  cancel(){
    this.okFunction(false, false);
    this.$uibModalInstance.close();
  }

  switchCreateAndSendMissionConfirmation() {
    if (this.hasMailServiceEnabled) {
      this.createAndSendMissionConfirmation = !this.createAndSendMissionConfirmation;
    }
  }
}