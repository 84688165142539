import {UserAccount} from './../../../../../data/account.data';

'use strict';

import {IRootScopeService, IScope} from "angular";
import {EmergencyResponse} from "../../../../../data/emergency.data";
import RestService from "../../../../../services/rest.service";
import PrivilegeService from '../../../../../services/privilege.service';
import {RolePrivilege} from '../../../../../data/privileges.enum';
import {
  ESelectiveAlarmStatus,
  SelectiveAlarmStateChange,
  SelectiveAlarmTableEntry
} from "../../../../../data/selective.alarms.data";


require('./selective.alarm.row.entry.component.scss');

export default class SelectiveAlarmRowEntryComponent {

  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;


  constructor() {
    this.restrict = 'A';
    this.template = require('./selective.alam.row.entry.component.html');
    this.scope = {
      selective: '='
    };
    this.controller = SelectiveAlarmRowEntryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  };
};

class SelectiveAlarmRowEntryComponentController {
  public selectiveAlarm: SelectiveAlarmTableEntry;
  private listeners = [];
  private newAlarmCount = 0;
  public $rootScope: angular.IRootScopeService;
  public $scope: angular.IScope;
  public $uibModal: any;
  public $log: angular.ILogService;
  public restService: RestService;
  public account: UserAccount;
  public privilegeService: PrivilegeService;
  public isAdmin: boolean = false;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: angular.ILogService, dataService, restService: RestService, $uibModal, privilegeService: PrivilegeService) {

    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.restService = restService;
    this.privilegeService = privilegeService;
    this.$scope = $scope;

    if (dataService.hasAccount()) {
      this.account = dataService.getAccount();
      this.isAdmin = this.account.admin;
    }

    this.listeners.push($scope.$watch('ctrl.selective', (oldValue, selectiveAlarmTableEntry: SelectiveAlarmTableEntry) => {
      if (selectiveAlarmTableEntry) {
        this.selectiveAlarm = selectiveAlarmTableEntry;
      }
    }));
    this.listeners.push(this.$rootScope.$on('update.selective_alarm', (event, selectiveAlarmState: SelectiveAlarmStateChange) => {
      if (selectiveAlarmState && selectiveAlarmState.id === this.selectiveAlarm.id) {
        if (selectiveAlarmState && selectiveAlarmState.status === ESelectiveAlarmStatus.DONE) {
          this.selectiveAlarm.status = selectiveAlarmState.status;
          this.$scope.$applyAsync();
        }
      }
    }))


    $scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  openMission() {
    if (!this.privilegeService.has(RolePrivilege.Home_Emergency_Details)) {
      return;
    }
    this.$uibModal.open({
      template: require('../../../../modals/alarms/mission.modal/mission.modal.html'),
      controller: 'MissionModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'hu',
      resolve: {
        externalId: () => this.selectiveAlarm.externalId,
        parentUserId: () => this.selectiveAlarm.userId,
        selectiveAlarmId: () => this.selectiveAlarm.id,
        allAlarmsDeletedFunction: () => {
          return () => {
            // nothing to do here
          }
        }
      }
    });
  }


  parseDate(timestamp: string): Date {
    return new Date(timestamp);
  }
}
