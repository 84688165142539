import angular from 'angular';
import 'angular-ui-bootstrap'
import services from '../../../services'


import UserDashboardComponentController from './user.dashboard.component/user.dashboard.component';
import FancyCheckboxComponentController from './checkbox/checkbox';
import PreviewComponentController from './preview/preview';
import SettingsAmobileLayersComponent from './settings.amobile.layers.component/settings.amobile.layers.component';
import SinkSettingsComponent from "./sink.settings.component/sink.settings.component";
import SinkTablerowComponent from "./sink.tablerow.component/sink.tablerow.component";
import PageControlsComponent from "./page.controls.component/page.controls.component";
import MissionReportSettingsComponent from "./mission.report.settings.component/mission.report.settings.component";
import FancyColorPickerComponent from './color.picker/color.picker';
import FancyNumberPickerComponent from './number.picker/number.picker';

export default angular.module('FE2.components.misc', ['ui.bootstrap', services])
  //.controller('MainController', MainController)
  .directive('userDashboard', () => new UserDashboardComponentController)
  .directive('fancyCheckbox', () => new FancyCheckboxComponentController)
  .directive('fancyColorPicker', () => new FancyColorPickerComponent)
  .directive('fancyNumberPicker', () => new FancyNumberPickerComponent)
  .directive('preview', () => new PreviewComponentController)
  .directive('settingsAmobileLayers', () => new SettingsAmobileLayersComponent)
  .directive('sinkSettings', () => new SinkSettingsComponent)
  .directive('sinkTablerow', () => new SinkTablerowComponent)
  .directive('pagination', () => new PageControlsComponent)
  .directive('missionReportSettings', () => new MissionReportSettingsComponent)
  .name;
