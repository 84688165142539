'use strict';

import {IScope} from "angular";
import {UserAccount} from "../../../../data/account.data";
import {AlarmTemplate} from "../../../../data/template.data";
import RestService from "../../../../services/rest.service";
import {ManualAlarmDetails} from "../../../../data/alarm.data";
import angular = require("angular");

require('./confirm.template.modal.css')

/* @ngInject */
export default class ConfirmTemplateModalController {
  public $scope: IScope;
  public $uibModalInstance: any;
  public $translate: any;
  public Notification: any;
  public restService: RestService;
  public template: AlarmTemplate;
  public account: UserAccount;
  public alarmText: string;
  public isLoading = false;
  public isEditDetails = true;
  public manualAlarmEdit: ManualAlarmDetails = {} as ManualAlarmDetails;
  public disabledUnitNames: string[] = [];

  constructor($scope: IScope, $uibModalInstance, $timeout: angular.ITimeoutService, $translate, Notification, dataService, restService: RestService, template: AlarmTemplate) {
    this.$scope = $scope
    this.$uibModalInstance = $uibModalInstance
    this.$translate = $translate
    this.Notification = Notification;
    this.restService = restService;
    this.template = template;
    this.account = dataService.getAccount();
    this.isEditDetails = template.editDetailsInTemplateGrid;

    if (template) {
      this.alarmText = template.message;
      const disabledUnits = template.units.filter(unit => unit.settings.disabled);
      this.disabledUnitNames = disabledUnits.map(unit => unit.name);
    }

    $timeout(() => {
      var element = document.getElementById('template-alarm-text');
      if (element) {
        element.focus();
      }
    })

    if (template.noConfirm && !template.editDetailsInTemplateGrid) {
      // send alarm immediately
      this.sendAlarm();
    }
  }



  cancel() {
    this.$uibModalInstance.close();
  }

  sendAlarm() {
    this.isLoading = true;

    //Gather all data
    var alarm = {} as any;
    var template = angular.copy(this.template);
    template.message = this.alarmText;
    alarm.data = template;


    //Units
    var units = '';
    for (var i = 0; i < template.units.length; i++) {
      units = units + template.units[i].code;
      if (i < template.units.length - 1) {
        units = units + ';';
      }
    }
    alarm.units = units;
    delete alarm.data.unitCodes;
    delete alarm.data.id;

    // Delete empty coordinates
    if (angular.isDefined(alarm.data.lat)) {
      if (alarm.data.lat === 0) {
        delete alarm.data.lat;
        delete alarm.data.lng;
      }
    }
    delete template.units;

    this.putEditDetailsInfo(alarm);

    this.restService.sendAlarm(alarm, () => {
      this.isLoading = false;
      this.$uibModalInstance.close();
      //Show popup
      this.$translate(['ALARM.SUCCESS_MESSAGE', 'ALARM.SUCCESS_TITLE']).then((translations) => {
        this.Notification.success({
          message: translations['ALARM.SUCCESS_MESSAGE'],
          title: translations['ALARM.SUCCESS_TITLE']
        });
      });
    }, (errorResponse) => {
      //Error occured
      this.isLoading = false;
    });
  }

  /**
   * method overriding the alarmdata if a detail view is set up for alarm template.
   * @param alarm alarm already with template data
   * @private
   */
  private putEditDetailsInfo(alarm: any) {
    if (!this.isEditDetails) {
      // no edit of details allowed, don't override data
      return;
    }
    if (angular.isDefined(this.manualAlarmEdit.alarmText)){
      alarm.data.message= this.manualAlarmEdit.alarmText;
    }

    if (angular.isDefined(this.manualAlarmEdit.selectedKeyword)) {
      alarm.data.keyword = this.manualAlarmEdit.selectedKeyword;
    }
    //Keyword
    if (angular.isDefined(this.manualAlarmEdit.keyword)) {
      if (this.manualAlarmEdit.keyword.additional) alarm.data.keyword_misc = this.manualAlarmEdit.keyword.additional;
      if (this.manualAlarmEdit.keyword.category) alarm.data.keyword_category = this.manualAlarmEdit.keyword.category;
      if (this.manualAlarmEdit.keyword.ident) alarm.data.keyword_ident = this.manualAlarmEdit.keyword.ident;
      if (this.manualAlarmEdit.keyword.color) alarm.data.keyword_color = this.manualAlarmEdit.keyword.color;
      if (this.manualAlarmEdit.keyword.ser) alarm.data.keyword_ser = this.manualAlarmEdit.keyword.ser;
    }
    //Description
    if (angular.isDefined(this.manualAlarmEdit.selectedKeyword)) {
      alarm.data.keyword_description = this.manualAlarmEdit.selectedKeywordDescription;
    }
    //Street
    if (angular.isDefined(this.manualAlarmEdit.selectedStreet)) {
      alarm.data.street = this.manualAlarmEdit.selectedStreet;
    }
    //House
    if (angular.isDefined(this.manualAlarmEdit.selectedHouse)) {
      alarm.data.house = this.manualAlarmEdit.selectedHouse;
    }
    //City
    if (angular.isDefined(this.manualAlarmEdit.selectedCity)) {
      alarm.data.city = this.manualAlarmEdit.selectedCity;
    }
    //PostalCode
    if (angular.isDefined(this.manualAlarmEdit.selectedPostalCode)) {
      alarm.data.postalCode = this.manualAlarmEdit.selectedPostalCode;
    }
    //Abbreviation
    if (angular.isDefined(this.manualAlarmEdit.selectedAbbreviation)) {
      alarm.data.city_abbr = this.manualAlarmEdit.selectedAbbreviation;
    }

    //Building
    if (angular.isDefined(this.manualAlarmEdit.selectedBuilding)) {
      alarm.data.building = this.manualAlarmEdit.selectedBuilding;
    }

    if (angular.isDefined(this.manualAlarmEdit.building)) {
      alarm.data.building = this.manualAlarmEdit.building.building;

      if (angular.isDefined(this.manualAlarmEdit.building.additional)) {
        alarm.data.location_additional = this.manualAlarmEdit.building.additional;
      }

      if (angular.isDefined(this.manualAlarmEdit.building.abbreviation)) {
        alarm.data.city_abbr = this.manualAlarmEdit.building.abbreviation;
      }

      if (angular.isDefined(this.manualAlarmEdit.building.id)) {
        alarm.data.building_id = this.manualAlarmEdit.building.id;
      }

      if (angular.isDefined(this.manualAlarmEdit.building.ident)) {
        alarm.data.building_ident = this.manualAlarmEdit.building.ident;
      }

      if (angular.isDefined(this.manualAlarmEdit.building.alarmObjectType)) {
        alarm.data.building_type = this.manualAlarmEdit.building.alarmObjectType;
      }

      if (this.manualAlarmEdit.building.lat && this.manualAlarmEdit.building.lat !== -1) {
        if (this.manualAlarmEdit.building.lng && this.manualAlarmEdit.building.lng !== -1) {
          alarm.data.lat = this.manualAlarmEdit.building.lat;
          alarm.data.lng = this.manualAlarmEdit.building.lng;
        }
      }
      // set this last, to have the manually set coordinate win over the one contained in building
      if (this.manualAlarmEdit.lat && this.manualAlarmEdit.lng) {
        // consider both coords are 0 as invalid
        if (!(this.manualAlarmEdit.lat === 0 && this.manualAlarmEdit.lng === 0)) {
          alarm.data.lat = this.manualAlarmEdit.lat;
          alarm.data.lng = this.manualAlarmEdit.lng;
        }
      }
    }
  }
}