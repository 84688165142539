import {AssetStrength} from "./vehicles.data";

export interface SectionsTemplatePaginated {
  content: SectionsTemplate[],
  totalPages: number,
  totalElements: number,
  number: number
}

export interface SectionsTemplate {
  id: string,
  userId: string,
  name: string,
  description: string,
  timestamp: number
}

export interface SectionsResponse {
  id: string,
  name: string,
  templateId: string,
  radio: string,
  radio2: string;
  locationName: string,
  phone: string,
  assignee: VehicleAssignment[],
  areaLeader: string,
  type: ESectionType;
  spatialType: ESpatialSectionTypes;
  state: EElementState;
  nbr: string,
  creator: string,
  logicalType: ELogicalSectionTypes;
  lat: number,
  lng: number
}

export enum ELogicalSectionTypes {
  DEFAULT = 'DEFAULT',
  COMMAND = 'COMMAND',
  LOGISTICS = 'LOGISTICS',
  ALLOCATION = 'ALLOCATION',
  COORDINATION_CENTER = 'COORDINATION_CENTER'
}

export interface VehicleAssignment {
  vehicleId: string,
  timestamp: number,
  vehicleName: string,
  vehicleShortname: string,
  strength: AssetStrength;
}

export enum EElementState {
  CREATED = 'CREATED',
  DELETED = 'DELETED'
}

export enum ESectionType {
  SPATIAL = 'SPATIAL',
  LOGICAL = 'LOGICAL'
}

export enum ESpatialSectionTypes {
  CONTACT_POINT = 'CONTACT_POINT',
  TREATMENT_PLACE = 'TREATMENT_PLACE',
  STAGING_AREA = 'STAGING_AREA',
  CARE_POINT = 'CARE_POINT',
  PATIENT_POINT = 'PATIENT_POINT',
  RMHP = 'RMHP',
  MEETING_POINT = 'MEETING_POINT',
  OTHER = 'OTHER',
  AVAILABILITY_AREA = 'AVAILABILITY_AREA',
}