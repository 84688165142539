'use strict';

import {ILogService, IRootScopeService, IScope, ITimeoutService, IWindowService} from "angular";
import RestService from "../../../services/rest.service";
import {UserAccount} from "../../../data/account.data";
import PrivilegeService from "../../../services/privilege.service";
import {RolePrivilege} from "../../../data/privileges.enum";

require('./templates.view.component.css');

/* @ngInject */
export default class TemplatesController {
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public $timeout: ITimeoutService;
  public $state: any;
  public $uibModal: any;
  public dataService: any;
  public restService: RestService;
  public $window: IWindowService;
  public $http: any;
  public helperService: any;
  public listeners = [];
  public activeTab: number = 0;
  public account: UserAccount;

  public priv: PrivilegeService;
  public hasAlarmTemplates: boolean = false;
  public hasSectionTemplates: boolean = false;

  public isFirstTimeLoading: boolean = true;
  public isLoading: boolean = false;

  public gridLoading: boolean;
  public grids: any;

  constructor($rootScope: IRootScopeService, $scope: IScope, $state, $timeout: ITimeoutService, $log: ILogService, $window: IWindowService, $http, $uibModal, restService: RestService, dataService, helperService, privilegeService: PrivilegeService) {
    this.$log = $log;
    this.$log.debug('TemplatesController started...');
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.restService = restService;
    this.$timeout = $timeout;
    this.$window = $window;
    this.$http = $http;
    this.helperService = helperService;
    this.priv = privilegeService;

    this.gridLoading = false;

    this.grids = [];
    this.initListeners();

    if (this.dataService.hasAccount()) {
      this.init();
    }else {
      // otherwise loading animation will be displayed
      this.isFirstTimeLoading= false;
    }
  }

  init() {
    this.$log.debug('Templates: Init TemplatesController...');
    this.hasSectionTemplates = this.priv.has(RolePrivilege.Templates_Locationmap_Section);
    this.hasAlarmTemplates =  this.priv.has(RolePrivilege.Alarm_Alerting_Templates) || this.priv.has(RolePrivilege.Alarm_Alerting_TemplateTable) || this.priv.has(RolePrivilege.Alarm_Alerting_FromTemplateOnly);
    this.account = this.dataService.getAccount();
    this.loadGrids(false);

  }

  /**
   * Load all grids
   */
  loadGrids(forceUpdate: boolean) {
    if (!this.hasAlarmTemplates) {
      return;
    }
    this.gridLoading = true;
    this.dataService.getAlarmTemplateGrids(forceUpdate, (data) => {
      this.$log.debug(data);
      this.grids = data;
      this.gridLoading = false;
    }, (err) => {
      this.$log.error(err);
      this.gridLoading = false;
    });
  };


  /**
   * Init event listeners
   */
  initListeners() {

    //Wait for new account
    this.listeners.push(
      this.$rootScope.$on('new.account', () => {
        //Init controller
        this.init();
      })
    );

    //Wait for LOGOUT
    this.listeners.push(
      this.$rootScope.$on('delete.account', () => {
        this.account = undefined;
      })
    );


    this.listeners.push(this.$rootScope.$on('tab.change.template', (event, data) => {
      this.onTabChange(data);
    }))


    this.listeners.push(this.$rootScope.$on('grid.updated', (event, grid) => {
      this.grids = grid;
    }));

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  onTabChange(data: number) {
    if (data >= 0 && data <= 1) {

      if (this.checkActiveTabPermission(data)) {
        this.activeTab = data;
        this.$rootScope.$emit('templates.view.tab', data);
      } else {
        this.switchToNextAllowedTab();
      }
    }
  }

  checkActiveTabPermission(tabIndex: number): boolean {
    switch (tabIndex) {
      case 0:
        return this.hasAlarmTemplates;
      case 1:
        return this.hasSectionTemplates;
      default:
        return false;

    }
  }

  switchToNextAllowedTab() {
    if (this.hasAlarmTemplates) {
      this.onTabChange(0);
    } else if (this.hasSectionTemplates) {
      this.onTabChange(1);
    }
  }

}

