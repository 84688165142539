'use strict';

require('./number.picker.scss');

export default class FancyNumberPickerComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'EA'
    this.scope = {
      translation: '=',
      data: '=',
      property: '=',
      deactive: '=',
      help: '=',
      min: '=',
      max: '=',
      unit: '='
    }
    this.template = require('./number.picker.html');

    this.controller = FancyNumberPickerComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class FancyNumberPickerComponentController {
  public translation: string;
  public property: string;
  public data: any;
  public deactive: boolean;
  public help: string;
  public unit: string;
  public min = Number.MIN_VALUE;
  public max = Number.MAX_VALUE;

}
