export interface TacticalSign {
  name: string;
  path: string;
}

export interface TacticalSignCategory {
  name: string;
  tacticalSign: TacticalSign[];
}

export interface TacticalSignSet {
  nameCategory: string;
  tacticalSign: TacticalSign;
}

export interface VehicleNameIDResponse {
  id: string;
  name: string;
}

export interface Vehicle {
  id: string;
  name: string;
  shortName: string;
  code: string;
  licensePlate: string;
  numberOfFaultMessages: number;
  isOverwriteImpulsActivated: boolean;
  overwriteImpuls: number;
  status: EVehicleStatus;
  statusChangedTimestamp: number;
  hasParkingPosition: boolean;
  parkingPosition: ParkingPositionResponse;
  column: number;
  row: number;
  remark: string;
  group: string;
  title: string;
  askForStrength: boolean;
  statusColor: string;
  statusTextColor: string;
  statusTranslation: string;
  currentStrength: AssetStrength;
  source: string;
  width: number;
  height: number;
  length: number;
  weight: number;
  vehicleType: EVehicleType;
  hasAlternativeVehicle: boolean;
  alternativeVehicle: AlternativeVehicleResponse;
  notAlarmabaleStatus: EVehicleStatus[];
  comingHomeActive: boolean;
  // these are the default values
  cntAssetBattalionLeader: number;
  cntAssetUnitLeader: number;
  cntAssetCrew: number;
  cntAssetBreathingProtection: number;
  connectedBatteryMonitorId: string;
  minVoltage: number;
  maxVoltage: number;
  batteryStatus: BatteryStatus;
  trackingMode: VehicleTrackingMode;
  trackingTimeToLive: number;
  allowSendPositionToExternalSystem: boolean;
  hasIOBatteryWriteAccess: boolean;
  tacticalSign: TacticalSignSet;
  vehicleIcon: IconFrontendRepresentation;
  capacityPatientsSitting: number;
  capacityPatientsLying: number;
  ttsName: string;
  sirenLocation: SirenLocation;
  notUsableDevices: number;
  availability: EVehicleAvailability;
  closeFaultsOnOkState: boolean;
  streamingURL: string;
  lipPollingAvailable: boolean;
  hasLocation: boolean;
  baseType: string;

  availableForIsochroneCalculation: boolean;
  assistancePeriod: number;
  isochroneColor: string;
}

export interface UpdateVehicleAvailabilityRequest {
  availability: EVehicleAvailability
}

export interface IconFrontendRepresentation {
  name: string;
  filename: string;
  category: string;
}

export interface VehicleIcons {
  categoryName: string;
  icons: IconFrontendRepresentation[];
}

export interface AlternativeVehicleResponse {
  name: string;
  vehicleId: string;
}

export interface ParkingPositionResponse {
  id: string;
  number: number;
  hasFrontGate: boolean;
  hasBackGate: boolean;
  frontGateName: string;
  backGateName: string;
}

export enum EVehicleStatus {
  STATUS_0 = 'STATUS_0',
  STATUS_1 = 'STATUS_1',
  STATUS_2 = 'STATUS_2',
  STATUS_3 = 'STATUS_3',
  STATUS_4 = 'STATUS_4',
  STATUS_5 = 'STATUS_5',
  STATUS_6 = 'STATUS_6',
  STATUS_7 = 'STATUS_7',
  STATUS_8 = 'STATUS_8',
  STATUS_9 = 'STATUS_9',
  STATUS_97 = 'STATUS_97',
  STATUS_98 = 'STATUS_98',
  STATUS_A = 'STATUS_A',
  STATUS_C = 'STATUS_C',
  STATUS_F = 'STATUS_F',
  STATUS_J = 'STATUS_J',
  STATUS_E = 'STATUS_E',
  STATUS_H = 'STATUS_H',
  STATUS_c = 'STATUS_c',
  STATUS_u = 'STATUS_u',
  STATUS_NOTRUF = 'STATUS_NOTRUF',
  STATUS_UNKNOWN = 'STATUS_UNKNOWN',
  EINSATZAUFTRAG = 'EINSATZAUFTRAG',
  EINRÜCKEN = 'EINRÜCKEN',
  LAGEMELDUNG = 'LAGEMELDUNG',
  AUFMERKSAMKEITSRUF = 'AUFMERKSAMKEITSRUF',
  EIGENSICHERUNG = 'EIGENSICHERUNG',
  MELDEN = 'MELDEN',
  TELEFON = 'TELEFON',
  WACHE_ANFAHREN = 'WACHE_ANFAHREN',
  SPRECHAUFFORDERUNG = 'SPRECHAUFFORDERUNG',
  ENTLASSEN = 'ENTLASSEN',
  SONDERRECHTE_ZUGELASSEN = 'SONDERRECHTE_ZUGELASSEN',
  SIRENE = 'SIRENE',
  ABGESTELLT = 'ABGESTELLT',
  POSITIV = 'POSITIV',
  STANDORT = 'STANDORT',
  NEGATIV = 'NEGATIV',
  GERÄT_ÜBERPRÜFEN = 'GERÄT_ÜBERPRÜFEN',
  SIRENE_AUSGELÖST = 'SIRENE_AUSGELÖST',
  SIRENE_NICHT_AUSGELÖST = 'SIRENE_NICHT_AUSGELÖST',
  SIRENE_BESETZT_GESPEICHERT = 'SIRENE_BESETZT_GESPEICHERT',
  SIRENE_BESETZT_ABGELEHNT = 'SIRENE_BESETZT_ABGELEHNT',
  SIRENE_OK = 'SIRENE_OK',
  SIRENE_FEHLER = 'SIRENE_FEHLER',
  SIRENE_ABGESCHALTET = 'SIRENE_ABGESCHALTET',
  SIRENE_SABOTAGE = 'SIRENE_SABOTAGE',
  SIRENE_STROM_OK = 'SIRENE_STROM_OK',
  SIRENE_BATTERIE_OK = 'SIRENE_BATTERIE_OK',
  SIRENE_BRAND_OK = 'SIRENE_BRAND_OK',
  SIRENE_SABOTAGE_QUITTUNG = 'SIRENE_SABOTAGE_QUITTUNG',
  SIRENE_STROMNETZFEHLER = 'SIRENE_STROMNETZFEHLER',
  SIRENE_BATTERIEFEHLER = 'SIRENE_BATTERIEFEHLER',
  SIRENE_BRAND = 'SIRENE_BRAND'
}

export interface VehiclesForTableau {
  all: Vehicle[];
  STATUS_1: Vehicle[];
  STATUS_2: Vehicle[];
  STATUS_3: Vehicle[];
  STATUS_4: Vehicle[];
  STATUS_6: Vehicle[];
  misc: Vehicle[];
}

export interface AddVehicleRequest {
  name: string,
  code: string
}

export interface PutVehicleAccessRequest {
  userId: string
}

export interface VehicleStatusHistoryResponse {
  data: VehicleStatusHistory;
}

export interface VehicleStatusHistory {
  data: Array<StatusEntryResponse>;
  currentPage: number;
  length: number;
  hasNext: boolean;
  hasPrevious: boolean;
}

export interface StatusEntryResponse {
  status: EVehicleStatus;
  vehicleId: string;
  vehicleName: string;
  address: string;
  timestamp: number;
  source: string;
  color: string;
  textColor: string;
  translation: string;
  technicalStatus: boolean;
  statusToDisplay: boolean;
  vehicleExists: boolean;
  statusString: string;
  strength: AssetStrength;
  relevantChangeForWorkload: boolean;
  availability: EVehicleAvailability;
}

export interface AssetStrength {
  cntBattalionLeader: number;
  cntUnitLeader: number;
  cntCrew: number;
  cntBreathingProtection: number;
}

export interface StatusGrid {
  rows: Row[];
}

export interface Row {
  row: number;
  columns: Column[];
}

export interface Column {
  column: number;
  values: Vehicle[];
  hasValue: boolean;
}

export enum EVehicleType {
  CAR = 'CAR',
  TRUCK = 'TRUCK',
  DRONE = 'DRONE',
  SIREN = 'SIREN'
}

export interface VehiclesPaginatedResponse {
  totalElements: number,
  totalPages: number,
  currentPage: number,
  length: number,
  data: Vehicle[]
}

export interface VehiclesSimplePaginatedResponse {
  totalElements: number,
  totalPages: number,
  currentPage: number,
  length: number,
  data: VehicleSimple[]
}

export interface VehicleSimple {
  id: string;
  name: string;
  shortName: string;
  status: EVehicleStatus;
  statusColor: string;
  availability: EVehicleAvailability;
}

export interface BatteryStatus {
  hasConnectedBatteryMonitor: boolean;
  currentVoltage: number;
  currentVoltageState: VoltageState;
  timestamp: number;
  timestampAsString: string;
  voltageUpToDate: boolean;
}

export enum VoltageState {
  GOOD = 'GOOD',
  TOO_LOW = 'TOO_LOW',
  TOO_HIGH = 'TOO_HIGH',
  UNKNOWN = 'UNKNOWN'
}

export enum VehicleTrackingMode {
  NONE = 'NONE',
  ALWAYS = 'ALWAYS',
  MISSION = 'MISSION'

}

export interface VehicleLocationChangeData {
  vehicleId: string;
  lat: number;
  lng: number;
  alt: number;
  timestamp: number;
  status: EVehicleStatus;
  externalResourceId?: string;
  estimatedArrival?: number;
  estimatedReturnTime?: number;
  flightMode?: EFlightMode;
  speed: number;
  batteryPercentage: number;
  mode: VehicleTrackingMode;
}

export enum EFlightMode {
  ALTITUDE_HOLD = 'ALTITUDE_HOLD',
  WAYPOINTS = 'WAYPOINTS',
  CLICK_AND_FLY = 'CLICK_AND_FLY',
  GPS = 'GPS',
  RETURN_HOME = 'RETURN_HOME',
  LAND = 'LAND',
  UNKNOWN = 'UNKNOWN'
}

export interface VehicleLocationResponse {
  id: string;
  name: string;
  status: EVehicleStatus;
  lat: number;
  lng: number;
  altitude: number;
  heading: number;
  timestamp: number;
  timestampString: string;
  source: string;
  mode: VehicleTrackingMode;
  speed: number;
  batteryPercentage: number;
  externalResourceId?: string;
  estimatedArrival?: number;
  estimatedReturnTime?: number;
  flightMode?: EFlightMode;
  statusColor: string;
  statusTextColor: string;
  statusTranslation: string;
  statusValue: string;
  statusTimestampString: string;
  availableForIsochroneCalculation: boolean;
  assistancePeriod: number;
  isochroneColor: string;
}

export interface VehicleLocationHistoryResponse {
  currentLocation: VehicleLocationResponse;
  segments: Segment[];
}

export interface Segment {
  positions: Coordinate[];
  color: string;
  status: EVehicleStatus;
  startTimestamp: number;
  endTimestamp: number;
  externalResourceIds: string[];
}

export interface Coordinate {
  lat: number;
  lng: number;
}

export interface VehicleMarkerHolder {
  vehicle: VehicleLocationResponse;
  marker: any;
}

export interface SirenLocation {
  lat: number;
  lng: number;
  radius?: number;
}

export interface VehicleSirenResponse {
  id: string;
  name: string;
  sirenLocation: SirenLocation;

  status: EVehicleStatus;
  statusColor: string;
  statusTextColor: string;
  statusTranslation: string;
  statusValue: string;
  statusTimestampString: string;

}

export interface VehicleForReport {
  id: string;
  name: string;
  shortName: string;
}

export interface VehicleForReportSlice {
  last: boolean;
  first: boolean;
  number: number;
  content: VehicleForReport[];
}

interface VehicleResponses {
  statusColor: string;
  statusTextColor: string;
  statusString: string;
  vehicleName: string;
  status: any;
  statusTranslation: string;
}

export interface UserLocationResponse {
  userName: string;
  userId: string;
  coordinates: Coordinate;
  vehicleResponses: VehicleResponses[];
}

export enum EVehicleAvailability {
  AVAILABLE = 'AVAILABLE',
  RESERVED = 'RESERVED',
  ASSIGNED = 'ASSIGNED'
}