'use strict'
import { IScope } from 'angular';
import { Vehicle } from "../../../../data/vehicles.data";
require("./vehicle.isochrones.component.scss")



export default class VehicleIsochronesComponent {

  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./vehicle.isochrones.component.html');
    this.scope = {
      vehicle: '='
    }
    this.controller = VehicleIsochronesComponentController
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class VehicleIsochronesComponentController {
  public vehicle: Vehicle;

  constructor(private $scope: IScope) {
    
  }
}