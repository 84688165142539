import angular from 'angular';
import 'angular-ui-bootstrap'

import MainController from './main.view.component/main.view.component';
import LoginController from './login.view.component/login.view.component';
import HomeController from './home.view.component/home.view.component';
import AddressbookController from './addressbook.view.component/addressbook.view.component';
import TemplatesController from './templates.view.component/templates.view.component';
import AdminController from './admin.view.component/admin.view.component';
import AlarmController from './alarm.view.component/alarm.view.component';
import WacheController from './wache.view.component/wache.view.component';
import AvailabilityController from './availability.view.component/availability.view.component';
import ExternalPersonsController from './external.persons.view.component/external.persons.view.component';
import ExternalRegistrationController from './external.registration.view.component/external.registration.view.component';
import StatisticsController from './statistic.view.component/statistic.view.component';
import UnitsController from './units.view.component/units.view.component';
import ObjectsController from './objects.view.component/objects.view.component';
import ExternalViewController from './external.view.component/external.view.component';
import CalendarController from './calendar.view.component/calendar.view.component';
import AssignmentsController from './assignments.view.component/assignments.view.component';
import LeaderboardController from './leaderboard.view.component/leaderboard.view.component';
import MissionsController from './missions.view.component/missions.view.component';
import QueuesController from './queues.view.component/queues.view.component';
import QueueMapController from './map.view.component/map.view.component';
import ExternalObjectController from './external.object.view.component/external.object.view.component';

import services from '../../services'
import modals from '../modals';
import directives from '../directives';

export default angular.module('FE2.controllers', ['ui.bootstrap', services, modals, directives])
  .controller('MainController', MainController)
  .controller('LoginController', LoginController)
  .controller('HomeController', HomeController)
  .controller('AddressbookController', AddressbookController)
  .controller('TemplatesController', TemplatesController)
  .controller('AdminController', AdminController)
  .controller('AlarmController', AlarmController)
  .controller('WacheController', WacheController)
  .controller('AvailabilityController', AvailabilityController)
  .controller('ExternalViewController', ExternalViewController)
  .controller('ExternalPersonsController', ExternalPersonsController)
  .controller('ExternalRegistrationController', ExternalRegistrationController)
  .controller('StatisticsController', StatisticsController)
  .controller('UnitsController', UnitsController)
  .controller('ObjectsController', ObjectsController)
  .controller('CalendarController', CalendarController)
  .controller('AssignmentsController', AssignmentsController)
  .controller('LeaderboardController', LeaderboardController)
  .controller('MissionsController', MissionsController)
  .controller('QueuesController', QueuesController)
  .controller('QueueMapController', QueueMapController)
  .controller('ExternalObjectController', ExternalObjectController)
  .name;
