import {AlarmDataSimple} from "./alarm.data";
import {ensureProgram} from "ts-loader/dist/utils";

export enum ESinkType {
  FE2_TO_FE2 = 'FE2_TO_FE2',
  GEO_BYTE = 'GEO_BYTE',
  BWB_OFFICE = 'BWB_OFFICE',
  FIREBOARD = 'FIREBOARD'
}

export interface SinkTableResponse {
  id: string;
  sinkType: ESinkType;
  note: string;
  active: boolean;
}

export interface SinkResponse extends SinkTableResponse {
  data: Map<string, string>;
}

export interface SinksResponsePaginated {
  hasNext: boolean;
  hasPrevious: boolean;
  content: SinkTableResponse[];
}

export interface SinkStateRequest {
  sinkId: string;
}

export interface SinkCurrentStateResponse {
  id: string;
  active: boolean;
}