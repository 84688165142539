'use strict';

import {ILogService, IScope} from "angular";
import RestService from "../../../../services/rest.service";
import {IOprintJobInfoResponsePage, IOPrintsInfoResponse, IOprintUpdateRequest} from "../../../../data/ioprint.data";

require('./ioprint.jobs.modal.scss');


/* @ngInject */
export default class IOprintJobsModalController {
  public $scope: IScope;
  public $uibModal;
  public $log: ILogService;
  public $uibModalInstance: any;
  public restService: RestService;
  public isLoading: boolean = false;
  public limit: number = 20;
  public ioprintInfo: IOPrintsInfoResponse;
  public data: IOprintJobInfoResponsePage;
  public oldUnitName: string;
  public editNameActive: boolean;
  private currentPageObject = 1;
  public ipAddress = '';


  constructor($scope: IScope, $log: ILogService, $uibModal, $uibModalInstance, restService: RestService, ioprintInfo: IOPrintsInfoResponse) {
    this.$scope = $scope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.ioprintInfo = ioprintInfo;
    this.oldUnitName = ioprintInfo.displayName;
    this.ipAddress = this.prepareIpAddress(ioprintInfo.ipAddress);
    this.pageChanged();
  }

  pageChanged() {
    this.isLoading = true;
    this.restService.getIOPrintJobsInfo(this.ioprintInfo.id, this.currentPageObject - 1, this.limit).then(response => {
      this.data = response;
      this.currentPageObject = this.data.number + 1;
    }).catch(error => {
      this.$log.error(error);
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  };

  /**
   * Save the units name
   */
  saveDeviceName() {
    this.editNameActive = false;
    if (this.ioprintInfo.displayName === '') {
      // Name should not be empty
      this.ioprintInfo.displayName = this.oldUnitName;
      return;
    }

    this.isLoading = true;
    let request: IOprintUpdateRequest = {
      name: this.ioprintInfo.displayName
    };
    this.restService.updateIOprint(this.ioprintInfo.id, request)
      .then(_ => {
        this.oldUnitName = this.ioprintInfo.displayName;
      })
      .catch(err => this.$log.error(err))
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      });

  }

  cancel() {
    this.$uibModalInstance.close();
  }

  preparePrintersResponse(response: Map<String, String>): string {
    let text = "";
    let val = Object.values(response);
    Object.keys(response).forEach((key: string, i) => {
      text += key + " = " + val[i] + "\n";
    });
    return text;
  }

  private prepareIpAddress(ipAddress: string) {
    if(!ipAddress || ipAddress.length <= 0){
      return '';
    }
    if(!ipAddress.includes(':', 10)){
      ipAddress=ipAddress + ":8443";
    }
    if(!ipAddress.startsWith("http")){
      ipAddress="https://"+ipAddress;
    }
    return ipAddress;
  }
}
