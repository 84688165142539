'use strict';

require('./checkbox.scss');

export default class FancyCheckboxComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'EA'
    this.scope = {
      translation: '=',
      data: '=',
      property: '=',
      deactive: '=',
      link: '=',
      tooltiptext: '=',
      customiconclass: '=',
      replaceicon: '='
    }
    this.template = require('./checkbox.html');

    this.controller = FancyCheckboxComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class FancyCheckboxComponentController {

  private translation: string;
  private property: string;
  private data: any;
  private deactive: boolean;
  private link: string;
  private tooltiptext: string;
  private customiconclass: string;
  public replaceicon: boolean;


  toggle() {
    if (this.deactive) {
      return;
    }
    if (this.property) {
      this.data[this.property] = !this.data[this.property];
    }else {
      this.data = !this.data;
    }
  }

  help() {
    if (this.link) {
      window.open(this.link, '_blank').focus();
    }
  }

  getState(){
    if (this.property){
      if(this.data){
        return this.data[this.property]
      }
      // field is a boolean contained in given data object but data itself is undefined return false
      return false;
    }
    // no property defined return data as boolean -> data itself is boolean flag
    return this.data;
  }

}
