import { IScope } from "angular";
import angular = require("angular");
import { Person } from "../../../../data/person.data";
import PrivilegeService from '../../../../services/privilege.service';
import {RolePrivilege} from '../../../../data/privileges.enum';

require('./person.properties.component.css');
export default class PersonPropertiesComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./person.properties.component.html');
    this.scope = {
      person: '='
    };
    this.controller = PersonPropertiesController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class PersonPropertiesController {

  public person: Person;
  public hasPersEdit: boolean;

  constructor(private $scope: IScope, private privilegeService: PrivilegeService) {
    this.hasPersEdit = this.privilegeService.has(RolePrivilege.Addressbook_Persons_Edit);
  }
}